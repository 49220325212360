const videoRoot = "/3dAssets/videos/";
const discoverRelianceRoot = "/assets/videos/discoverHotspots/";
export const VideoString = {
  LOBBYLOOP: videoRoot + "Lobby_Loop.mp4", //"Lobby_Loop.mp4",
  LOOBYTOINFO: videoRoot + "ToInfoBooth.mp4",
  LOBBYTOAudi: videoRoot + "ToAuditorium.mp4",
  LOOBYTOPRODUCTSTALL: videoRoot + "ToProductStall.mp4",
  LOBBYTOBREAKOUT: videoRoot + "breakout.mp4",
  BREAKOUTLOOP: videoRoot + "breakoutLoop.mp4",
  AUDITORIUM: videoRoot + "Auditorium.mp4",
  WORKSHOPLOBBY: videoRoot + "Work Shop.mp4",
  WORKSHOP: videoRoot + "Workshop Audi.mp4",
  LOBBYTOCAREER: videoRoot + "ToCareerCafe.mp4",
  LOOBYTOMODI: videoRoot + "ToModi.mp4",
  LOBBYTODIVISION: videoRoot + "ToDivision.mp4",
  LOBBYTOCONNECT: videoRoot + "ToConnect.mp4",

  Room1Frame: videoRoot + "IT/Auditorium.mp4",
  Room2Frame: videoRoot + "Marketing/Auditorium.mp4",
  Room3Frame: videoRoot + "Supply chain/Auditorium.mp4",
  Room4Frame: videoRoot + "Commercial/Auditorium.mp4",
  Room5Frame: videoRoot + "Digital/Auditorium.mp4",
  Room6Frame: videoRoot + "Finance/Auditorium.mp4",
  Room7Frame: videoRoot + "HR/Auditorium.mp4",
  LOBBYTOGAME: videoRoot + "ToGame.mp4",
  LOBBYBACKTOWINNER: videoRoot + "toBackWallWinners.mp4",
  LOBBYBACKTOAWARDS: videoRoot + "toBackWallAwards.mp4",
  LOBBYTOPHOTOBOOTH: videoRoot + "toPhotobooth.mp4",
  LOBBYTOAGENDA: videoRoot + "toAgenda.mp4",

  LOBBYLEFTTORIGHT: videoRoot + "left_to_right.mp4",
  LOBBYRIGHTTOLEFT: videoRoot + "Left_to_Right_Backwards.mp4",
  LOBBYBACKSIDE: videoRoot + "Back_Side.mp4",

  THROWBACK: discoverRelianceRoot + "TUP_Rewind.mp4",
  TESTIMONIALS: discoverRelianceRoot + "TUP_Testimonials.mp4",
  RELIANCEFORANEWINDIA: discoverRelianceRoot + "NEWINDIA.mp4",
};
