import { LobbyHotspots } from "../../data/Lobby/hotspots";
import { PointType } from "../fixed/Types";

export const menuItemsId = {
  Lobby: 1,
  Audi: 2,
  // BreakoutRoom: 3,
  Meet: 3,
  Explore: 4,
  TeamBuilding: 5,
  DivisionBooth: 6,
  MyProfile: 7,
};
export const workshopMenuItem = {
  id: "Workshop",
  name: "Workshop",
  class: "icon-audi",
  subMenus: [
    { id: 1, name: "CHAT", class: "icon-QNA" },
    { id: 2, name: "Poll", class: "icon-Poll" },
  ],
};
export const GalleryIndex = {
  Consumer: 0,
  Professional: 1,
  Active: 2,
  Luxe: 3,
};

export const GalleryItems = [
  {
    id: "Consumer",
    name: "Consumer",
    class: "icon-audi",
  },
  {
    id: "Professional",
    name: "Professional",
    class: "icon-audi",
  },
  {
    id: "Active",
    name: "Active",
    class: "icon-audi",
  },
  {
    id: "Luxe",
    name: "Luxe",
    class: "icon-audi",
  },
];

export const WallOfFameItems = [
  {
    id: "frontWall",
    name: "front side",
    class: "icon-audi",
  },
];

export const menuItemsIndex = {
  Lobby: 0,
  Audi: 1,
  Explore: 2,
  // BreakoutRoom: 3,

  Meet: 3,
  TeamBuilding: 4,
  DivisionBooth: 5,
  MyProfile: 6,
};

export const menuItems = [
  {
    id: menuItemsId.Lobby,
    name: "Lobby",
    class: "icon-lobby",
  },
  {
    id: menuItemsId.Audi,
    name: "Auditorium",
    class: "icon-audi-reliance",
    get disabled() {
      return !LobbyHotspots.Audi.enabled;
    },
    subMenus: [
      { id: 1, name: "CHAT", class: "icon-QNA" },
      { id: 2, name: "Poll", class: "icon-Poll" },
    ],
  },
  {
    id: menuItemsId.Explore,
    name: "Discover RELIANCE",
    class: "icon-discover-rel",
  },
  // {
  //   id: menuItemsId.BreakoutRoom,
  //   name: "Career Café",
  //   class: "icon-rel-career-cf  icon-lg",
  // },

  {
    id: menuItemsId.Meet,
    name: "Meet the Jury",
    class: "icon-productbooth",
    get disabled() {
      return true;
    },
  },
  {
    id: menuItemsId.TeamBuilding,
    name: "IDEA WALL",
    class: "icon-idea-wall-rel",
    get disabled() {
      return !LobbyHotspots.Audi.enabled;
    },
  },
  {
    id: menuItemsId.DivisionBooth,
    name: "Brands portfolio",
    class: "icon-division-q",
    get disabled() {
      return true;
    },
  },
  {
    id: menuItemsId.MyProfile,
    name: "My Profile",
    class: "icon-profile-rel",
  },
];

export const AudisubMenuId = {
  QNA: 1,
  POll: 2,
};

export const NetworkingSubmenuId = {
  Connect: 1,
  Speaker: 2,
  Sessions: 3,
  Chat: 4,
};
