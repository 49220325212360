import firebase, { apps, database } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";
import { AppString } from "./AppString";
import * as projectData from "../../data/Lobby/hotspots";
import * as realTimeData from "../../data/Lobby/realtimeData";
import { PointType, PointValues } from "../../const/fixed/Types";

let firebaseConfig = {
  apiKey: "AIzaSyDWgOC6ECP8b1dW2B7ZncCLnT4ejBcFjPg",
  authDomain: "reliance-tup-9.firebaseapp.com",
  databaseURL:
    "https://reliance-tup-9-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "reliance-tup-9",
  storageBucket: "reliance-tup-9.appspot.com",
  messagingSenderId: "694038948206",
  appId: "1:694038948206:web:77eaf7df40e98e0f75b97f",
  measurementId: "G-0YJZQNJ89P",
};
firebase.initializeApp(firebaseConfig);

window.firebaseInstance = firebase;

export const firebaseApp = firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realDB = firebase.database();

export const analytics = firebase.analytics();

export const storage = firebase.storage();

export const signInWithId = (email, password) => {
  return new Promise(async (res, rej) => {
    try {
      const cred = await auth.signInWithEmailAndPassword(email, password);
      console.log(cred.user.email + " is logged in right now");

      res();
    } catch (error) {
      rej(error);
    }
  });
};
const defaultImageUrl =
  "https://firebasestorage.googleapis.com/v0/b/djfarmademo.appspot.com/o/profileimages%2Fblank-avatar.png?alt=media&token=2af15226-9bd7-47ce-bc72-f3c1a12a0780";

export const signUpWithId = (email, password, name) => {
  return new Promise(async (res, rej) => {
    try {
      const response = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      await response.user.updateProfile({
        displayName: name,
        photoURL: defaultImageUrl,
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};
export const getOnlineStatus = (email) => {
  return new Promise(async (res, rej) => {
    try {
      let Id = email;
      Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
      Id = Id.toLowerCase();
      const snap = await realDB.ref(`/loggedInUser/${Id}`).once("value");
      if (snap.exists()) {
        if (snap.val().state === "online") {
          rej({
            code: "siggend In",
            message: "Already signed in from another device",
          });
        } else {
          res();
        }
      } else {
        res();
      }
    } catch (e) {
      console.log(e);
      rej(e);
    }
  });
};
export const getUserDetailsNew = (email) => {
  return new Promise(async (res, rej) => {
    try {
      const doc = await firestore
        .collection(AppString.USERS_COLLECTION)
        .doc(email)
        .get();
      if (doc.exists) {
        res(doc.data());
      }
    } catch (err) {
      rej(err);
    }
  });
};
export const loadUser = async (
  email,
  password,
  forceCreateNew = false,
  name,
  college
) => {
  return new Promise(async (res, rej) => {
    try {
      // console.log(forceCreateNew);
      console.log(email);
      await signInWithId(email, password);
      res();
    } catch (err) {
      if (err.code === "auth/internal-error" && forceCreateNew) {
        try {
          window.userData = await setUserData(email, {
            name,
            college,
          });
          await signUpWithId(email, password, window.userData.name);

          // window.userData = await getUserDetails(email, {
          //   name,
          //   college,
          // });

          return;
        } catch (error) {
          if (error.code === "user not found") {
            console.log(error);
            rej(error);
          }
        }
      }
      console.log(err);
      rej({
        code: err.code,
        message: err.message,
      });
    }
  });
};

export const signOut = (noRefresh) => {
  auth.signOut().then(function () {
    // window.open("https://" + document.domain, "_self");
    if (!noRefresh) {
      window.location.reload();
      window.location.href = "/";
    }
  });
};

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const generatePushID = (function () {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS =
    "-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz";

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function () {
    var now = new Date().getTime();
    var duplicateTime = now === lastPushTime;
    lastPushTime = now;

    var timeStampChars = new Array(8);
    for (var i = 7; i >= 0; i--) {
      timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
      // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
      now = Math.floor(now / 64);
    }
    if (now !== 0)
      throw new Error("We should have converted the entire timestamp.");

    var id = timeStampChars.join("");

    if (!duplicateTime) {
      for (i = 0; i < 12; i++) {
        lastRandChars[i] = Math.floor(Math.random() * 64);
      }
    } else {
      // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
      for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
        lastRandChars[i] = 0;
      }
      lastRandChars[i]++;
    }
    for (i = 0; i < 12; i++) {
      id += PUSH_CHARS.charAt(lastRandChars[i]);
    }
    if (id.length != 20) throw new Error("Length should be 20.");

    return id;
  };
})();

export const youtube_parser = (url) => {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
};

// R1Rcm
export const getUserDetails = (email, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      firestore
        .collection(AppString.USERS_COLLECTION)
        .doc(email)
        .get()
        .then((doc) => {
          if (doc.exists) {
            firestore
              .collection(AppString.USERS_COLLECTION)
              .doc(email)
              .update({
                ...data,
                isAdmin: true,
                firstName: data.name.split(" ")[0],
                lastName: data.name.split(" ")[1]
                  ? data.name.split(" ")[1]
                  : "",
                workshop: "workshop-1",
              })
              .then(() => {
                resolve(doc.data());
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject({ code: "* User not registered" });
          }
        });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const setUserData = (email, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      firestore
        .collection(AppString.USERS_COLLECTION)
        .doc(email)
        .set({
          ...data,
          email,
          isAdmin: true,
          firstName: data.name.split(" ")[0],
          lastName: data.name.split(" ")[1] ? data.name.split(" ")[1] : "",
          workshop: "workshop-1",
        })
        .then((res) => {
          resolve({
            ...data,
            email,
            isAdmin: true,
            firstName: data.name.split(" ")[0],
            lastName: data.name.split(" ")[1] ? data.name.split(" ")[1] : "",
            workshop: "workshop-1",
          });
        })
        .catch(() => {
          reject({ code: "Something went wrong!" });
        });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const getProjectRealTimeData = () => {
  return new Promise(async (res, rej) => {
    try {
      const doc = await firestore
        .collection(AppString.BACKSATGE)
        .doc(AppString.BACKSTAGEREALTIMEDATA)
        .get();
      if (!doc.exists) {
        rej({
          code: "NoDataFound",
          message: "no such document exists.",
        });
      }
      const docData = doc.data();
      Object.keys(docData).forEach((key) => {
        let ItemObject = docData[key];
        Object.keys(ItemObject).forEach((itmeKey) => {
          if (realTimeData[`${key}`].hasOwnProperty(itmeKey)) {
            if (typeof realTimeData[`${key}`][itmeKey] !== "object") {
              realTimeData[`${key}`][itmeKey] = ItemObject[itmeKey];
            } else {
              realTimeData[`${key}`][itmeKey] = {
                ...realTimeData[`${key}`][itmeKey],
                ...ItemObject[itmeKey],
              };
            }
          } else {
            if (typeof ItemObject[itmeKey] !== "object") {
              realTimeData[`${key}`][itmeKey] = ItemObject[itmeKey];
            } else {
              realTimeData[`${key}`][itmeKey] = {
                ...ItemObject[itmeKey],
              };
            }
          }
        });
      });
      res(docData);
    } catch (error) {
      rej(error);
    }
  });
};

export const getAudiLinkListener = (callback) => {
  window.audiListener = firestore
    .collection(AppString.BACKSATGE)
    .doc(AppString.BACKSTAGELINK)
    .onSnapshot(
      (doc) => {
        if (!doc.exists) {
          callback({
            code: "NoDataFound",
            message: "no such document exists.",
          });
        }
        let link = doc.data().AudiData.link;
        callback(null, link);
      },
      (err) => {
        callback(err);
      }
    );
};
export const getQnaZoneLinkListener = (callback) => {
  window.audiListener = firestore
    .collection(AppString.BACKSATGE)
    .doc(AppString.BACKSTAGELINK)
    .onSnapshot(
      (doc) => {
        if (!doc.exists) {
          callback({
            code: "NoDataFound",
            message: "no such document exists.",
          });
        }
        let link = doc.data().ExploreData ? doc.data().ExploreData.link : "";
        callback(null, link);
      },
      (err) => {
        callback(err);
      }
    );
};

export const getBreakoutRoomListener = (callback) => {
  window.breakoutRoomListener = firestore
    .collection(AppString.BACKSATGE)
    .doc(AppString.BACKSTAGELINK)
    .onSnapshot(
      (doc) => {
        if (!doc.exists) {
          callback({
            code: "NoDataFound",
            message: "no such document exists.",
          });
        }
        let data = doc.data().BreakoutRoomHotspot;
        callback(null, data);
      },
      (err) => {
        callback(err);
      }
    );
};
export const removeAudiLinkListener = () => {
  if (window.audiListener) {
    window.audiListener();
  }
};

export const getProjectDataListener = (callback) => {
  window.staticDataListener = firestore
    .collection(AppString.BACKSATGE)
    .doc(AppString.BACKSTAGELINK)
    .onSnapshot(
      (doc) => {
        if (!doc.exists) {
          callback({
            code: "NoDataFound",
            message: "no such document exists.",
          });
        }
        const docData = doc.data();
        Object.keys(docData).forEach((key) => {
          let ItemObject = docData[key];
          Object.keys(ItemObject).forEach((itmeKey) => {
            if (projectData[`${key}`].hasOwnProperty(itmeKey)) {
              if (typeof projectData[`${key}`][itmeKey] !== "object") {
                projectData[`${key}`][itmeKey] = ItemObject[itmeKey];
              } else {
                projectData[`${key}`][itmeKey] = {
                  ...projectData[`${key}`][itmeKey],
                  ...ItemObject[itmeKey],
                };
              }
            } else {
              if (typeof ItemObject[itmeKey] !== "object") {
                projectData[`${key}`][itmeKey] = ItemObject[itmeKey];
              } else {
                projectData[`${key}`][itmeKey] = {
                  ...ItemObject[itmeKey],
                };
              }
            }
          });
        });
        callback(null);
      },
      (err) => {
        callback(err);
      }
    );
};
export const getProjectData = () => {
  return new Promise(async (res, rej) => {
    try {
      const doc = await firestore
        .collection(AppString.BACKSATGE)
        .doc(AppString.BACKSTAGELINK)
        .get();
      if (!doc.exists) {
        rej({
          code: "NoDataFound",
          message: "no such document exists.",
        });
      }
      // console.log(projectData);
      const docData = doc.data();
      Object.keys(docData).forEach((key) => {
        let ItemObject = docData[key];
        Object.keys(ItemObject).forEach((itmeKey) => {
          if (projectData[`${key}`].hasOwnProperty(itmeKey)) {
            if (typeof projectData[`${key}`][itmeKey] !== "object") {
              projectData[`${key}`][itmeKey] = ItemObject[itmeKey];
            } else {
              projectData[`${key}`][itmeKey] = {
                ...projectData[`${key}`][itmeKey],
                ...ItemObject[itmeKey],
              };
            }
          } else {
            if (typeof ItemObject[itmeKey] !== "object") {
              projectData[`${key}`][itmeKey] = ItemObject[itmeKey];
            } else {
              projectData[`${key}`][itmeKey] = {
                ...ItemObject[itmeKey],
              };
            }
          }
        });
      });
      // console.log(projectData);
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const turnOnUserCard = (email) => {
  return new Promise(async (resolve, reject) => {
    try {
      let path = "/users";
      var dbref = realDB
        .ref(path)
        .orderByChild("email")
        .equalTo(email)
        .limitToFirst(1);
      // var dbref = realDB.ref(path).orderByChild('email').startAt(email.toUpperCase()).endAt(email.toLowerCase()+'\uf8ff').limitToFirst(1);
      const query = await dbref.once("value");
      if (query.exists()) {
        let key = Object.keys(query.val())[0];
        console.log(query.val()[key]);
        if (!query.val()[key].enabled) {
          realDB.ref(`/users/${key}`).update({
            enabled: true,
          });
        }
        resolve();
      } else {
        reject({
          code: "NoCardUserFound",
          message: "No Such User Is Registered",
        });
      }
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
};

export const addConnectProfiles = (data) => {
  let path = "/users";
  realDB.ref(path).set(data);
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
};
// addConnectProfiles(connectUserData)

export const attachConnectProfilesListener = (callback) => {
  let path = "/users";
  window.dbref = realDB.ref(path).orderByChild("enabled").equalTo(true);
  window.connectListener = window.dbref.on(
    "value",
    (query) => {
      if (query.exists()) {
        callback(false, query.val());
      } else {
        callback(true, {
          code: "NoUserFound",
          message: "NoUserIsFoundForConnect",
        });
      }
    },
    (err) => {
      callback(true, err);
    }
  );
};

export const removeConnectProfilesListener = () => {
  window.dbref.off("value", window.connectListener);
};

//#region Login analytics
const getUserIdForAnalytics = (user, getEmail) => {
  return new Promise((resolve, reject) => {
    try {
      if (user.email) {
        let Id = user.email;
        Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
        Id = Id.toLowerCase();
        resolve(Id);
      } else {
        let err = {
          code: "NoEmailFound",
          message: "Please pass a valid User Object",
        };
        throw err;
      }
    } catch (error) {
      reject(error);
    }
  });
};

const checkForTimeStampAvailable = () => {
  return new Promise(async (res, rej) => {
    try {
      if (
        window.todaysDateTimestamp === undefined ||
        window.todaysDateTimestamp === null
      ) {
        const result = await getCurrentTimeStampV2();
        window.todaysDateTimestamp = result.todaysDateTimestamp;
        return;
      }
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const getCurrentTimeStampV2 = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await fetch("https://dj-timeserver.glitch.me");
      const result = await res.json();
      var currDateWeb = new Date(result.epoch);
      var currDateLocal = new Date();
      var webTimeDiff = currDateWeb.getTime() - currDateLocal.getTime();
      let todaysDateTimestamp = `${currDateWeb.getFullYear()}-${
        currDateWeb.getMonth() + 1
      }-${currDateWeb.getDate()}`;
      resolve({ currDateWeb, webTimeDiff, todaysDateTimestamp });
    } catch (err) {
      reject(err);
    }
  });
};

export const addLoginAnalytics = (user) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();

      let Id = await getUserIdForAnalytics(user);

      let path = `analytics/${window.todaysDateTimestamp}/${Id}`;
      const reference = realDB.ref(path);

      const snapShot = await reference.once("value");

      if (snapShot.exists()) {
        await reference.update({
          email: user.email,
          name: user.displayName,
          lastLoginTime: firebase.database.ServerValue.TIMESTAMP,
        });
      } else {
        await reference.set({
          email: user.email,
          name: user.displayName,
          firstLoginTime: firebase.database.ServerValue.TIMESTAMP,
          lastLoginTime: firebase.database.ServerValue.TIMESTAMP,
          college: user.college,
          mobile: user.mobile,
        });
      }

      res();
    } catch (error) {
      console.log(error);
      rej(error);
    }
  });
};

let diconnectReference = null;
export const addDisconnectListener = (user) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = await getUserIdForAnalytics(user);
      let path = `analytics/${window.todaysDateTimestamp}/${Id}`;
      const reference = realDB.ref(path);
      diconnectReference = reference.onDisconnect();
      await diconnectReference.update({
        lastLogoutTime: firebase.database.ServerValue.TIMESTAMP,
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const addLogoutAnalytics = (user) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = await getUserIdForAnalytics(user);
      let path = `analytics/${window.todaysDateTimestamp}/${Id}`;
      const reference = realDB.ref(path);

      await reference.update({
        lastLogoutTime: firebase.database.ServerValue.TIMESTAMP,
      });

      if (diconnectReference) {
        diconnectReference.cancel();
      }

      res();
    } catch (error) {
      rej(error);
    }
  });
};

export const updateUserStatus = (userId, email) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (userId == undefined) {
        reject({
          code: "UserIDWasNull",
          message: "Please try again later",
        });
      } else {
        if (userId === "") {
          reject({
            code: "UserIDWasNull",
            message: "Please try again later",
          });
        }
      }
      var userCheck = realDB.ref("loggedInUser/" + userId);
      await userCheck.update({
        state: "online",
        lastChange: firebase.database.ServerValue.TIMESTAMP,
        email,
      });
      let disconnectRef = await userCheck.onDisconnect();
      await disconnectRef.update({
        state: "offline",
        lastChange: firebase.database.ServerValue.TIMESTAMP,
        email,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

//#endregion

//#region Analytics
export const updateUserLocation = (user, location) => {
  // console.log(user, location);
  return new Promise(async (resolve, reject) => {
    try {
      if (location == undefined || user == undefined) {
        resolve();
      }
      let Id = await getUserIdForAnalytics(user, true);
      var userCheck = realDB.ref("loggedInUser/" + Id);
      await userCheck.update({
        location: location,
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const decreaseLocationCount = (userId, location) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (window.locationDBRef) {
        window.locationDBRef.cancel();
      }
      var userCheck = realDB.ref("locationCounter/");
      await userCheck.update({
        [location]: firebase.database.ServerValue.increment(-1),
      });
      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const addRealtimeHotspotAnalytics = (user, hotspotName) => {
  return new Promise(async (res, rej) => {
    try {
      await checkForTimeStampAvailable();
      let Id = await getUserIdForAnalytics(user);
      let path = `analytics/${window.todaysDateTimestamp}/${Id}`;

      const reference = realDB.ref(path);
      await reference.update({
        [hotspotName]: firebase.database.ServerValue.increment(1),
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};

//#endregion
export const getLeaderboardData = (callback) => {
  const leaderboardDocRef = firestore
    .collection("userScore")
    .orderBy("total", "desc");
  window.leaderboardListener = leaderboardDocRef.onSnapshot(
    (snapShot) => {
      if (!snapShot.empty) {
        let data = [];
        snapShot.docs.forEach((doc) => {
          data.push(doc.data());
        });
        if (callback) {
          callback(null, data);
        }
      } else {
        let err = {
          code: "NoData",
          message: "No data found",
        };
        if (callback) {
          callback(err);
        }
      }
    },
    (err) => {
      if (callback) {
        callback(err);
      }
    }
  );
};
//#region leaderBoard
export const addScore = (user, pointName) => {
  return new Promise(async (res, rej) => {
    try {
      let Id = user.email;
      Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
      Id = Id.toLowerCase();

      let userScorePath = "/usersScore/" + Id;
      let leaderboradPath = "/leaderboard/" + Id;

      const userScoreRef = realDB.ref(userScorePath);
      const leaderboardScoreRef = realDB.ref(leaderboradPath);
      const userSocreSnapShot = await userScoreRef.once("value");
      if (userSocreSnapShot.exists()) {
        //seconf time
        let scoreData = userSocreSnapShot.val();
        console.log(scoreData);
        if (scoreData.hasOwnProperty(pointName)) {
          scoreData[pointName] += 1;
        } else {
          scoreData[pointName] = 1;
        }
        if (scoreData.score) delete scoreData.score;
        let finalScore = getScore(scoreData);
        console.log(scoreData);
        console.log(finalScore);
        await userScoreRef.update({
          ...scoreData,
          score: finalScore,
        });
        await leaderboardScoreRef.update({
          name: user.displayName,
          email: user.email,
          score: finalScore,
        });
      } else {
        let scoreData = {
          [`${pointName}`]: 1,
        };
        let finalScore = getScore(scoreData);
        await userScoreRef.set({
          [`${pointName}`]: 1,
          score: finalScore,
        });
        await leaderboardScoreRef.set({
          name: user.displayName,
          email: user.email,
          score: finalScore,
        });
      }
      res();
    } catch (error) {
      rej(error);
    }
  });
};
export const getUserScore = (user) => {
  return new Promise(async (res, rej) => {
    try {
      let Id = user.email;
      Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
      Id = Id.toLowerCase();
      let leaderboradPath = "/leaderboard/" + Id;
      const ref = realDB.ref(leaderboradPath);
      const snapshot = await ref.once("value");
      if (snapshot.exists()) {
        res(snapshot.val().score);
      } else {
        res(0);
      }
    } catch (error) {
      rej(error);
    }
  });
};
const getScore = (data) => {
  let finalScore = 0;
  Object.keys(data).forEach((key) => {
    if (!PointValues[key].oneTime) {
      finalScore += data[key] * PointValues[key].value;
      console.log(key, data[key], PointValues[key].value, finalScore);
    } else {
      finalScore += PointValues[key].value;
      console.log(key, 1, PointValues[key].value, finalScore);
    }
  });
  return finalScore;
};
const reverseObject = (object) => {
  var newObject = {};
  var keys = [];
  for (var key in object) {
    keys.push(key);
  }
  for (var i = keys.length - 1; i >= 0; i--) {
    var value = object[keys[i]];
    newObject[keys[i]] = value;
  }
  return newObject;
};
export const getLeaderboard = (callback) => {
  realDB
    .ref()
    .child("leaderboard")
    .orderByChild("score")
    .limitToLast(10)
    .on(
      "value",
      function (snapshot) {
        if (snapshot.exists()) {
          let arr = [];
          snapshot.forEach(function (child) {
            arr.push(child.val());
          });
          arr = arr.reverse();
          callback(false, arr);
        } else {
          callback({
            code: "noUserFound",
            message: "noUserFound",
          });
        }
      },
      function (errorObject) {
        callback(errorObject);
      }
    );

  // let path = '/leaderboard'
  // window.leaderboardListener = realDB.ref(path).orderByChild('score')//.limitToLast(10);
  // window.leaderboardListener.on('value', (snapShot) => {
  //     if (!snapShot.exists()) {
  //         callback({
  //             code: 'noUserFound', message: 'noUserFound'
  //         })
  //         return;
  //     }
  //     var data = snapShot.val();
  //     const sortedData = reverseObject({...data})
  //     console.log(data)
  //     console.log(sortedData)
  //     callback(false, sortedData);
  // }, err => {
  //     console.log(err)
  //     callback(err);
  // })
};
export const removeLeaderboardListener = () => {
  if (window.leaderboardListener) window.leaderboardListener.off();
};

export const getUserRank = (user) => {
  return new Promise(async (res, rej) => {
    try {
      let Id = user.email;
      Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
      Id = Id.toLowerCase();
      let leaderboradPath = "/leaderboard/";
      const ref = realDB.ref(leaderboradPath).orderByChild("score");
      const snapshot = await ref.once("value");
      if (snapshot.exists()) {
        let data = snapshot.val();
        let leaderboardKeys = Object.keys(data);
        let maxCount = leaderboardKeys.length;
        let index = leaderboardKeys.indexOf(Id);
        // console.log(index, maxCount)
        if (index !== -1) {
          res(maxCount - index);
        } else {
          res(maxCount + 1);
        }
      } else {
        res(0);
      }
    } catch (error) {
      rej(error);
    }
  });
};
//#endregion
let userData = {
  designation: "Deputy Manager",
  email: "amurugan@r1rcm.com",
  enabled: true,
  linked_ln_profile:
    "www.linkedin.com/in/murugan-allumbulan-subramani-9a497b109",
  name: "A Murugan",
  profile_image:
    "https://firebasestorage.googleapis.com/v0/b/r1rcm-virtual-event.appspot.com/o/images%2F00f7a215-325a-4a42-9d4f-aacbb9d1b682.jpeg?alt=media&token=76719806-ade1-410c-9c8a-3c6a33a5d8aa",
  what_keeps_you_amazing: "Results",
};

export const addleader = (user) => {
  return new Promise(async (res, rej) => {
    try {
      let Id = user.email;
      Id = Id.replace(/[&\/\\#,+$~%.'":*?<>{}]/g, "");
      Id = Id.toLowerCase();

      let nodePath = "/users/" + Id;
      const nodeRef = realDB.ref(nodePath);
      await nodeRef.set(user);
      res();
    } catch (error) {
      rej(error);
    }
  });
};

window.parent.sendUserData = async () => {
  console.log("senduser called");
  // window.user.email = window.userData.email;

  const ref = firestore
    .collection("photoBoothuserData")
    .doc(window.userData.email);
  const docRef = await ref.get();
  console.log("docRef", docRef.data());
  if (!docRef.exists) {
    // console.log("doc not exist");
    if (typeof window.parent.needUserData !== "undefined") {
      window.parent.needUserData(null);
    }
  } else {
    const data = docRef.data();
    const PhotBoothID = data.phoboothID;
    if (typeof window.parent.needUserData !== "undefined") {
      window.parent.needUserData(PhotBoothID);
    }
  }
};

window.parent.saveUserImage = (e, button) => {
  // console.log(e);
  console.log("save image function");
  // console.log(window.userData);
  // console.log(window.userData.college);
  var nameFist = window.userData.name.split(" ")[0];
  var collegeFirst = window.userData.college.split(" ")[0];
  var imageFileName = nameFist + "_" + collegeFirst + ".jpeg";
  console.log(imageFileName);

  storage
    .ref("/images/")
    .child(`${imageFileName}`)
    .putString(e, "data_url", { contentType: "image/jpg" })
    .then(() => {
      // Getting Download Link
      storage
        .ref("images")
        .child(`${imageFileName}`)
        .getDownloadURL()
        .then((url) => {
          // console.log("uploaded image url");
          // console.log(url);
          window.parent.PhotoBoothImageUrl = url;
          window.parent.imageFileName = imageFileName;
        });
      console.log("Image uploaded");

      // button.style.display = "block";
      button.onclick = function fun() {
        console.log("sharebtn clicked");

        if (typeof window.parent.showSharePopUp !== "undefined") {
          window.parent.showSharePopUp(window.parent.PhotoBoothImageUrl);
        }

        //   console.log(
        //     `mailto:mayurbansal98@gmail.com?Subject=Digital%20Jalebi%20Photo%20Booth%20&amp;Body=My%20photo%20from%20Digital%20Jalebi%20Photo%20Booth!%20${window.parent.PhotoBoothImageUrl}`
        //   );
        //   window.open(
        //     "_blank",
        //     `mailto:mayurbansal98@gmail.com?Subject=Digital%20Jalebi%20Photo%20Booth%20&amp;Body=My%20photo%20from%20Digital%20Jalebi%20Photo%20Booth!%20${window.parent.PhotoBoothImageUrl}`
        //   );
      };
    });
};

window.parent.updateUsersResult = (id) => {
  console.log("updateUSersResult function called");
  let val = "";
  if (id == 0) {
    val = "Customer Value";
  } else if (id == 1) {
    val = "Ownership Mindset";
  } else if (id == 2) {
    val = "Respect";
  } else if (id == 3) {
    val = "Integrity";
  } else if (id == 4) {
    val = "One Team";
  } else if (id == 5) {
    val = "Excellence";
  }
  // window.user.email
  firestore
    .collection("photoBoothuserData")
    .doc(window.userData.email)
    .set({
      isPlayed: true,
      phoboothID: id,
      phoboothResult: val,
    })
    .then(() => {
      console.log("Saved");
    });
};

export const checkForSpecialUser = async (user, callback) => {
  const ref = firestore
    .collection(AppString.BACKSATGE)
    .doc(AppString.SPECIALUSER);
  const docRef = await ref.get();
  if (!docRef.exists) {
    callback({ code: "NoDocFound", message: "noDocFound" });
  }
  const data = docRef.data();
  // if (data.userUid) {
  //     if (data.userUid.indexOf(user.uid) !== -1) {
  //         callback(null, data.link);
  //     } else {
  //         callback({ code: 'notAdmin', message: 'simpleUser' })
  //     }
  // } else {
  //     callback({ code: 'notAdmin', message: 'simpleUser' })
  // }
  if (window.jry) {
    callback(null, data.link);
  } else {
    callback({ code: "notAdmin", message: "simpleUser" });
  }
};

export const addUserScore = (user, teamId, gameName, score) => {
  return new Promise(async (res, rej) => {
    try {
      // incrementUserPlayCount(user, gameName)
      let userId = user.email;
      // console.log(userId, teamId, gameName, score)
      const userDocRef = firestore.collection(AppString.UserScore).doc(userId);
      // const leaderboardDocRef = firestore
      //   .collection(AppString.Leaderboard)
      //   .doc(teamId);

      console.log(user, teamId, gameName, score);
      const result = await firestore.runTransaction(async (transaction) => {
        const userDoc = await transaction.get(userDocRef);
        console.log("userDoc", userDoc);
        // const leaderboardDoc = await transaction.get(leaderboardDocRef);
        let userScore = {
          id: user.uid,
          email: user.email,
          name: user.displayName,

          scores: 0,
          // total: 0,
        };

        if (userDoc.exists) {
          userScore = {
            ...userScore,
            ...userDoc.data(),
          };
        }

        // if (userScore.scores.hasOwnProperty(gameName)) {
        if (userScore.scores <= score) {
          userScore.scores = score;
          userDoc.exists
            ? transaction.update(userDocRef, userScore)
            : transaction.set(userDocRef, userScore);
        } else {
          let err = {
            code: "ScoredLess",
            message: "Earlier Score was higher",
          };
          throw err;
        }
        // } else {
        //   userScore.scores = score;
        // }
        // //check new total and if greater than update
        // let newTotal = 0;
        // Object.keys(userScore.scores).forEach((gameName) => {
        //   newTotal += userScore.scores[gameName];
        // });
        // // console.log(userScore)

        // console.log("newTotal", newTotal);
        console.log("userScore", userScore);
        // if (newTotal > userScore.total) {
        //   userScore.total = newTotal;
        //   userDoc.exists
        //     ? transaction.update(userDocRef, userScore)
        //     : transaction.set(userDocRef, userScore);
        // } else {
        //   let err = {
        //     code: "TotalScoreLess",
        //     message: "Earlier Total Score was higher",
        //   };
        //   throw err;
        // }
      });
      res();
    } catch (error) {
      rej(error);
    }
  });
};
